<template>
    <div class="state">
        <div class="state-title">
            <i class="iconfont icon-dicengjiagou"></i>
            <span>{{$t('myState.title')}}</span>
        </div>
        <div v-if="sendForm.level == 0" class="vip-title">
            <span>{{$t('login.vipState_01')}}</span>
            <span style="color:#f00">VIP</span>
            <span>，{{$t('login.vipState_02')}}</span>
            <span style="color:#f00">{{$t('nav.upgrade')}}</span>
            <span>。</span>
        </div>
        <div class="information-say" v-else>
            <div class="information-say-title">
                <div>{{$t('myState.text_01')}}~</div>
                <div>
                    <span @click="tabState(1)" :class="{tabstyle: istabStyle == 1}">{{$t('myState.imgState')}}</span>
                    <span @click="tabState(2)" :class="{tabstyle: istabStyle == 2}">{{$t('myState.videoState')}}</span>
                </div>
            </div>
            <div v-if="isRouterAlive">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    :placeholder="$t('myState.text_02')"
                    v-model="stateForm.content">
                </el-input>
                <div class="informmation-imgss" v-if="istabStyle == 1">
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                    <el-upload
                        name="imgFile"
                        :action="$isApi.uploadImg"
                        :data="cccdata"
                        list-type="picture-card"
                        :on-success="stateSuccess"
                        :on-preview="viewSuccess"
                        :on-remove="stateRemove">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
                <div class="informmation-imgss" v-if="istabStyle == 2">
                    <el-upload
                        name="imgFile"
                        class="avatar-uploader"
                        :data="cccdata"
                        :action="$isApi.uploadImg"
                        :show-file-list="false"
                        accept=".mp4"
                        :on-success="videoSuccess">
                        <video 
                            v-if="videoMp4"
                            :src="$isApi.http + videoMp4"
                            class="video-avatar"
                            controls="controls" />
                        <i v-else class="el-icon-plus video-icon"></i>
                    </el-upload>
                    <div class="video-mp4-c">{{$t('myState.text_03')}}</div>
                </div>
                <el-button v-if="stateForm.content || stateForm.fileUrl" @click="goState" style="margin:10px 0 0 2px" type="primary" size="mini">{{$t('myState.text_04')}}</el-button>
            </div>
        </div>
        <div class="state-center" v-if="stateList.length > 0">
            <div class="state-center-item" v-for="(item,index) in stateList" :key="index">
                <div>
                    <i class="iconfont icon-shoucang"></i>
                    <span>{{$isPulic.timestampToTime(item.createDate)}}</span>
                    <span @click="deleteState(item.id)">{{$t('myState.delete')}}</span>
                </div>
                <div>
                    <div>{{item.content}}</div>
                    <div class="state-center-imgs" v-if="item.fileUrl instanceof Array">
                        <el-image 
                            fit="contain"
                            class="state-center-imgss"
                            v-for="(img,index) in item.fileUrl" 
                            :key="index" 
                            :src="img"
                            :preview-src-list="item.fileUrl">
                        </el-image>
                    </div>
                    <div class="state-center-imgs" v-else-if="item.fileUrl.IsPicture('.jpg') || item.fileUrl.IsPicture('.png') || item.fileUrl.IsPicture('.gif') || item.fileUrl.IsPicture('.jpeg')">
                        <el-image 
                            fit="contain"
                            class="state-center-imgss"
                            :src="item.fileUrl"
                            :preview-src-list="[item.fileUrl]">
                        </el-image>
                    </div>
                    <div v-else-if="item.type == 'video'">
                        <video 
                            v-if="item.fileUrl"
                            :src="$isApi.http + item.fileUrl"
                            class="video-avatar"
                            controls="controls" />
                    </div>
                </div>
            </div>
            <el-pagination
                class="page-size-number"
                background
                @current-change="currentChange"
                :page-size="20"
                :current-page="pageNo"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
        </div>
        <div class="state-nul" v-else>{{$t('myState.text_05')}}</div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            dialogImageUrl: "",
            videoMp4: "",
            isRouterAlive: true,
            dialogVisible: false,
            istabStyle: 1,
            fileList: [],
            pageNo: 1,
            total: 1,
            stateForm: {
                userId: "",
                content: "",
                fileUrl: "",
                type: "img"
            },
            cccdata: {imgType:'dynamic'},
            textarea: "",
            stateList: [],
            userId: ""
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage['sendForm'])
        this.stateForm.userId = JSON.parse(sessionStorage['sendForm']).userId
        this.stateLi(this.pageNo)
    },
    methods: {
        tabState(e){
            this.stateForm.fileUrl = ''
            this.istabStyle = e
            if(e == 1){
                this.stateForm.type = "img"
            }else if(e == 2){
                this.stateForm.type = "video"
            }else{
                this.stateForm.type = "text"
            }
        },
        stateLi(num){
            var json = {
                userId:JSON.parse(sessionStorage['sendForm']).userId,
                pageNo: num,
                pageSize: 20,
            }
            this.$isAxios.axiosGet(this.$isApi.showUserDynamics,json,(res)=>{
                if(res.data.result != null){
                    res.data.result.forEach( item => {
                        if(item.fileUrl){
                            if(item.fileUrl.indexOf(",") != -1){
                                item.fileUrl = item.fileUrl.split( ',' )
                            }
                        }
                    })
                    this.total = res.data.total
                    this.stateList = res.data.result
                }
            })
        },
        // 分页
        currentChange(e){
            this.stateLi(e)
        },
        // 删除图片
        stateRemove(file, fileList) {
            var arrImg = []
            for(let i=0;i<fileList.length;i++){
                arrImg.push(this.$isApi.http + fileList[i].response.path)
            }
            this.stateForm.fileUrl = JSON.stringify(arrImg)
        },
        // 查看图片
        viewSuccess(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        // 上传成功
        stateSuccess(response, file, fileList){
            var arrImg = []
            for(let i=0;i<fileList.length;i++){
                arrImg.push(this.$isApi.http + fileList[i].response.path)
            }
            this.stateForm.fileUrl = this.$isPulic.acTiveArrStringFun(arrImg)
        },
        // 上传视频
        videoSuccess(res, file){
            this.videoMp4 = res.path
            this.stateForm.fileUrl = res.path
        },
        // 发布动态
        goState(){
            this.$isAxios.axiosPost(this.$isApi.addDynamic,this.stateForm,(res)=>{
                if(res.data.message == 'success'){
                    this.stateForm.content = ""
                    this.stateForm.fileUrl = ""
                    this.dialogImageUrl = ""
                    this.videoMp4 = ""
                    this.isRouterAlive = false
                    this.$nextTick(() => {
                        this.isRouterAlive = true
                        this.stateLi()
                        this.$message({
                            message: this.$i18n.t('myState.text_06'),
                            type: 'success'
                        })
                    })
                }
            })
        },
        // 删除动态
        deleteState(id){
            this.$confirm(this.$i18n.t('myState.text_08'), this.$i18n.t('giftComponents.reminder'), {
                confirmButtonText: this.$i18n.t('userLeft.success'),
                cancelButtonText: this.$i18n.t('giftComponents.cancel'),
                type: 'warning'
            }).then(() => {
                this.$isAxios.axiosGet(this.$isApi.removeDynamic,{dynamicId:id},(res)=>{
                    if(res.data.result == 'success'){
                        this.stateLi()
                        this.$message({
                            message: this.$i18n.t('myPhoto.deleteSuccess'),
                            type: 'success'
                        })
                    }
                })
            }).catch(() => {})
        }
    }
}
</script>

<style scoped>
.vip-title{
    height: max-content;
    font-size: 12px;
    color: #999;
    margin-top: 20px;
    padding-left: 10px;
}
.video-mp4-c{
    font-size: 12px;
    color: #f00;
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 3px;
}
.state{
    background: #fff;
    padding: 20px;
}
.state-nul{
    text-align: center;
    line-height: 100px;
    color: #999;
}
.information-say-title{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.video-icon{
    width: 140px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px dashed #ccc;
    border-radius: 4px;
    overflow: hidden;
    font-size: 40px;
    color: #999;
    background: #fff
}
.information-say-title>div:nth-child(1){
    background-image:-webkit-linear-gradient(135deg,#f00, #e400ff);
    -webkit-background-clip:text; 
    -webkit-text-fill-color:transparent;
}
.information-say-title>div:nth-child(2){
    display: flex;
    width: max-content
}
.information-say-title>div:nth-child(2)>span{
    width: max-content;
    height: 24px;
    font-size: 12px;
    line-height: 24px;
    padding: 0 10px;
    border-radius: 15px;
    color: #333;
    display: block;
    cursor: pointer;
}
.tabstyle{
    background: #333;
    color: #fff !important;
}
.state-center{
  margin: 18px 0 0 2px;
  background: rgba(255, 0, 0, .04);
  padding: 22px 10px;
}
.state-center-item{
  position: relative;
  height: max-content;
  border-left: 1px dashed rgba(0, 0, 0, .2);
  margin-left: 10px;
  padding-bottom: 40px;
}
.state-center-item:last-child{
  padding-bottom: 0 !important;
}
.state-center-item>div:nth-child(1){
  position: absolute;
  left: -8px;
  top: -10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.state-center-item>div:nth-child(1)>i{
  color: rgba(255, 0, 0, .6);
  margin-right: 13px;
  flex-grow: 0;
}
.state-center-item>div:nth-child(1)>span:nth-child(2){
  color: #999;
  font-size: 13px;
  flex: 1;
  display: block;
}
.state-center-item>div:nth-child(1)>span:nth-child(3){
  font-size: 13px;
  color: #999;
  cursor: pointer;
}
.state-center-item>div:nth-child(1)>span:nth-child(3):hover{
  color: #f00;
}
.state-center-item>div:nth-child(2){
  margin-left: 20px;
  padding-top: 20px;
}
.state-center-item>div:nth-child(2)>div:nth-child(1){
  font-size: 14px;
  line-height: 22px;
  color: #333;
  font-weight: bold
}
.state-center-imgs{
  display: flex;
  flex-wrap: wrap;
  height: max-content;
  margin-top: 16px;
}
.state-center-imgss{
  width: 80px;
  height: 80px;
  object-fit: scale-down;
  background: #000;
  margin: 0 6px 6px 0;
}
</style>